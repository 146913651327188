Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a file which has been uploaded. This might be an entirely new file
not yet associated with the UDF or it might be an existing file. If a new
file it might also display any progress if still uploading or an error if
unsuccessful in uploading.

<template>
  <div class="list-group-item">
    <div class="row">
      <div class="col-10">
        <template v-if="file.isAttachable()">
          <input type="hidden" :name="`${name}[][filename]`" :value="file.fileName" />
          <input type="hidden" :name="`${name}[][blob_id]`" :value="file.blobId" />
          <input type="hidden" :name="`${name}[][signed_id]`" :value="file.signedId" />
        </template>

        <div :class="{ 'pending-delete': file.pendingDelete }">
          <a v-if="file.url" :href="file.url" target="_blank">{{ file.fileName }}</a>
          <template v-else>{{ file.fileName }}</template>
        </div>

        <div class="text-danger error-message" v-if="file.errorMessage">
          {{ file.errorMessage }}
        </div>

        <div class="progress upload-progress-container" v-if="file.isUploading()">
          <div class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar" aria-value-min="0" aria-valuemax="100"
            :aria-valuenow="file.percent()" :style="{ width: `${file.percent()}%` }">
          </div>
        </div>
      </div>

      <div class="col-1 text-right">
        <delete-button v-if="deletable" :confirm="false" size="sm"
          @click="file.pendingDelete = true" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.pending-delete { text-decoration: line-through }
</style>

<script>
import DeleteButton from 'bootstrap/delete_button'

export default {
  components: { DeleteButton },
  inheritAttrs: false,
  props: ['file', 'name', 'disabled'],
  computed: {
    deletable() {
      return !this.disabled && !this.file.pendingDelete && !this.file.errorMessage
    },
  },
}
</script>
